import React from "react"
// import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/Layout"
import Alert from "../components/Alert"

import { NavSocialLink } from "../components/Sidebar"
// Prevent fontawesome from dynamically adding its css since we did it manually above
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDiscord } from "@fortawesome/free-brands-svg-icons"
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons"
import arccorp from "../images/bg/spacehead_alert_arccorp_planet.png"
import anvil from "../images/bg/SC_01_Wallpaper_1500x500.jpg"

const AlertSocialLink = styled(NavSocialLink)`
    color: var(--cosmic-blue);
    font-size: calc(32px + (48 - 32) * ((100vw - 300px) / (1600 - 300)));
    padding: 0 0 1rem 1rem;
`

// const DayCard = ({ data, children, id }) => {
//     return(

//     )
// }

const ShipCard = styled.div`
    & .card {
        border: none;
        background-image: url(${anvil});
        background-repeat: no-repeat;
        /* background-position: 25px 100px; */
        background-size: cover;
        width: 100%;
        border-radius: 20px;

        position: relative;

        .card-body {
            min-height: 200px;
            z-index: 1;
        }

        .card-footer {
            background-color: var(--racecar-orange);
            color: var(--milky-way-white);
            border: none;
            border-radius: 0 0 20px 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            z-index: 1;
            &:hover {
                background-color: var(--milky-way-white);
                color: var(--racecar-orange);
            }
        }
    }
`

const ShipCardWrapperBg = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    position: absolute;
    /* z-index: -1; */
`

{
    /* <div className="card bg-dark w-100">
    <div className="card-body">
        <h3 className="display1 card-title">Anvil Aerospace</h3>
    </div>
    <a href="#" className="card-footer btn">
        <span className="display2 ts-0">VIEW SHIPS >></span>
    </a>
</div> */
}

export default () => (
    <Layout
        headerTitle="Intergalactic Aerospace Expo"
        headerSubtitle="Sponspored By Anvil Aerospace"
    >
        <div className="row">
            <div className="col-xl-6">
                <h2 className="header-mt-0">
                    What is the Intergalactic Aerospace Expo?
                </h2>
                <p>
                    The Intergalactic Aerospace Expo has come a long way.
                    Renowned pilots like Chelsea Yan and members of the Navy’s
                    famed ‘Wreckless’ Squadron 999 dazzle attendees with
                    impressive flight maneuvers, while ship and component
                    manufacturers unveil their latest wares. At its core though,
                    the Intergalactic Aerospace Expo is made for those young
                    dreamers who find themselves staring up at the sky to count
                    running lights.
                </p>
            </div>
            <div className="col-xl-6">
                <Alert
                    title="Attention, Citizen!"
                    subtitle="New to the Stanton System?"
                    bg={arccorp}
                >
                    <AlertSocialLink
                        //href={data.prismicConfig.data.youtube_url} TODO URL
                        href="#"
                        target="_blank"
                        style={{ float: "right" }}
                    >
                        <FontAwesomeIcon icon={faDiscord} />
                    </AlertSocialLink>
                    <p>
                        The expo is located on the planet ArcCorp at it’s major
                        city, Area 18. Need a lift? Visit us on Discord for a
                        Taxi!
                    </p>
                </Alert>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <h2 className="display1">Expo Catalog</h2>
            </div>
            <div className="col-xl-6">
                <div className="card-group">
                    <div className="card bg-success">
                        <div className="card-body d-flex flex-row align-items-baseline justify-content-between">
                            <h5 className="card-title header-m-0">Day 1</h5>
                            <FontAwesomeIcon icon={faUnlock} />
                        </div>
                    </div>
                    <div className="card bg-dark">
                        <div className="card-body d-flex flex-row align-items-baseline justify-content-between">
                            <h5 className="card-title header-m-0">Day 2</h5>
                            <FontAwesomeIcon icon={faLock} />
                        </div>
                    </div>
                    <div className="card bg-dark">
                        <div className="card-body d-flex flex-row align-items-baseline justify-content-between">
                            <h5 className="card-title header-m-0">Day 3</h5>
                            <FontAwesomeIcon icon={faLock} />
                        </div>
                    </div>
                    <div className="card bg-dark">
                        <div className="card-body d-flex flex-row align-items-baseline justify-content-between">
                            <h5 className="card-title header-m-0">Day 4</h5>
                            <FontAwesomeIcon icon={faLock} />
                        </div>
                    </div>
                    <div className="card bg-dark">
                        <div className="card-body d-flex flex-row align-items-baseline justify-content-between">
                            <h5 className="card-title header-m-0">Day 5</h5>
                            <FontAwesomeIcon icon={faLock} />
                        </div>
                    </div>
                    <div className="card bg-dark">
                        <div className="card-body d-flex flex-row align-items-baseline justify-content-between">
                            <h5 className="card-title header-m-0">Day 6</h5>
                            <FontAwesomeIcon icon={faLock} />
                        </div>
                    </div>

                    <div className="card bg-dark">
                        <div className="card-body d-flex flex-row align-items-baseline justify-content-between">
                            <h5 className="card-title header-m-0">Day 7</h5>
                            <FontAwesomeIcon icon={faLock} />
                        </div>
                    </div>
                    <div className="card bg-dark">
                        <div className="card-body d-flex flex-row align-items-baseline justify-content-between">
                            <h5 className="card-title header-m-0">Day 8</h5>
                            <FontAwesomeIcon icon={faLock} />
                        </div>
                    </div>
                    <div className="card bg-dark">
                        <div className="card-body d-flex flex-row align-items-baseline justify-content-between">
                            <h5 className="card-title header-m-0">Day 9</h5>
                            <FontAwesomeIcon icon={faLock} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-6">
                <ShipCard>
                    <div className="card">
                        <ShipCardWrapperBg />
                        <div className="card-body">
                            <h3 className="display1 header-mt-0 card-title">
                                Anvil Aerospace
                            </h3>
                        </div>
                        <a href="#" className="card-footer btn">
                            <span className="display2 ts-0">Day 1</span>
                            <span className="display2 ts-0">VIEW SHIPS >></span>
                        </a>
                    </div>
                </ShipCard>
            </div>
        </div>
    </Layout>
)

{
    /* <div className="col-xl-6">
                <div className="card-deck">
                    <div className="row">
                        <div className="card bg-dark">
                            <div className="card-body">
                                <h5 className="card-title header-mt-0">Day</h5>
                                <p className="card-text">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This card has even longer content
                                    than the first to show that equal height
                                    action.
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">
                                    Last updated 3 mins ago
                                </small>
                            </div>
                        </div>
                        <div className="card bg-dark">
                            <div className="card-body">
                                <h5 className="card-title header-mt-0">Day</h5>
                                <p className="card-text">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This card has even longer content
                                    than the first to show that equal height
                                    action.
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">
                                    Last updated 3 mins ago
                                </small>
                            </div>
                        </div>
                        <div className="card bg-dark">
                            <div className="card-body">
                                <h5 className="card-title header-mt-0">Day</h5>
                                <p className="card-text">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This card has even longer content
                                    than the first to show that equal height
                                    action.
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">
                                    Last updated 3 mins ago
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="card bg-dark">
                            <div className="card-body">
                                <h5 className="card-title header-mt-0">Day</h5>
                                <p className="card-text">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This card has even longer content
                                    than the first to show that equal height
                                    action.
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">
                                    Last updated 3 mins ago
                                </small>
                            </div>
                        </div>
                        <div className="card bg-dark">
                            <div className="card-body">
                                <h5 className="card-title header-mt-0">Day</h5>
                                <p className="card-text">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This card has even longer content
                                    than the first to show that equal height
                                    action.
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">
                                    Last updated 3 mins ago
                                </small>
                            </div>
                        </div>
                        <div className="card bg-dark">
                            <div className="card-body">
                                <h5 className="card-title header-mt-0">Day</h5>
                                <p className="card-text">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This card has even longer content
                                    than the first to show that equal height
                                    action.
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">
                                    Last updated 3 mins ago
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="card bg-dark">
                            <div className="card-body">
                                <h5 className="card-title header-mt-0">Day</h5>
                                <p className="card-text">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This card has even longer content
                                    than the first to show that equal height
                                    action.
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">
                                    Last updated 3 mins ago
                                </small>
                            </div>
                        </div>
                        <div className="card bg-dark">
                            <div className="card-body">
                                <h5 className="card-title header-mt-0">Day</h5>
                                <p className="card-text">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This card has even longer content
                                    than the first to show that equal height
                                    action.
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">
                                    Last updated 3 mins ago
                                </small>
                            </div>
                        </div>
                        <div className="card bg-dark">
                            <div className="card-body">
                                <h5 className="card-title header-mt-0">Day</h5>
                                <p className="card-text">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This card has even longer content
                                    than the first to show that equal height
                                    action.
                                </p>
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">
                                    Last updated 3 mins ago
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */
}
