import React from "react"
import styled from "styled-components"

const AlertWrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border: 1px solid var(--cosmic-blue);
    user-select: none;
    position: relative;
`
const AlertWrapperBg = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    position: absolute;
    border-radius: 18px;
    z-index: -1;
`
const AlertWrapperBgPlanet = styled.div`
    width: 100%;
    height: 100%;
    background-image: url('${props => props.bg}');
    background-repeat: no-repeat;
    background-position: 25px 100px;
    background-size: contain;
    z-index: -2;
    position: absolute;
`

const AlertHeader = styled.div`
    font-family: "Audiowide", "Aldrich", sans-serif;
    display: flex;
    flex-direction: column wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: var(--cosmic-blue);
    border-radius: 18px 18px 0 0;
    padding: 1rem;

    @media (min-width: 576px) {
        padding: 1rem 2rem;
    }
`

const AlertHeaderCircleWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
`
const AlertHeaderCircle = styled.div`
    width: calc(16px + (25 - 16) * ((100vw - 300px) / (1600 - 300)));
    height: calc(16px + (25 - 16) * ((100vw - 300px) / (1600 - 300)));
    background: var(--main-bg-color);
    border-radius: 50%;
    margin-right: 1em;

    &:last-child {
        margin-right: 0;
    }
`

const AlertBody = styled.div`
    color: #baecff;
    padding: 1rem;

    @media (min-width: 576px) {
        padding: 1rem 2rem;
    }
`

const Alert = ({ title, subtitle, bg, children }) => {
    return (
        <AlertWrapper>
            <AlertWrapperBg />
            <AlertWrapperBgPlanet bg={bg} />
            <AlertHeader>
                <h2 className="display3 header-m-0 ts-0">{title}</h2>
                <AlertHeaderCircleWrapper>
                    <AlertHeaderCircle />
                    <AlertHeaderCircle />
                    <AlertHeaderCircle />
                </AlertHeaderCircleWrapper>
            </AlertHeader>
            <AlertBody>
                <h3 className="display4 header-mt-0">{subtitle}</h3>
                {children}
            </AlertBody>
        </AlertWrapper>
    )
}

export default Alert
